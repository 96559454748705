<script setup >
import Header from '@/components/admin/common/header-admin.vue'
import Menu from '@/components/admin/common/menu-admin.vue'
import Footer from '@/components/admin/common/footer-admin.vue'
</script>

<template>
  <div>
    <Header />
    <Menu />
    <main id="main" class="main">
      <section class="section dashboard">
        <RouterView />
      </section>
    </main>
    <Footer />
  </div>
</template>

<script>
import '@/assets/js/main.js'

export default {}
</script>
