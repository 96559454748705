export default ({
  namespaced: true,
  state: {
    productos: [],
    selectedCurrency: 'ext',
    subTotalCompraLocal: 0,
    subTotalCompraExt: 0,
    totalCompraLocal: 0,
    totalCompraExt: 0,
    totalIgvLocal: 0,
    totalIgvExt: 0
  },
  mutations: {
    agregarProducto (state, producto) {
      const item = state.productos.find(p => p.producto.codigo === producto.producto.codigo)
      if (item) {
        item.cantidad += producto.cantidad
        item.producto.totalLocal = parseFloat((item.producto.precio_local_sin_igv * item.producto.cantidad).toFixed(2))
        item.producto.totalExt = parseFloat((item.producto.precio_ext_sin_igv * item.producto.cantidad).toFixed(2))
      } else {
        producto.cantidad = producto.cantidad || 1
        producto.producto.totalLocal = parseFloat((producto.producto.precio_local_sin_igv * producto.cantidad).toFixed(2))
        producto.producto.totalExt = parseFloat((producto.producto.precio_ext_sin_igv * producto.cantidad).toFixed(2))
        state.productos.push(producto)
      }
    },
    actualizarProducto (state, producto) {
      const item = state.productos.find(p => p.producto.codigo === producto.producto.codigo)
      if (item) {
        item.cantidad = producto.cantidad
        item.producto.totalLocal = parseFloat((item.producto.precio_local_sin_igv * item.cantidad).toFixed(2))
        item.producto.totalExt = parseFloat((item.producto.precio_ext_sin_igv * item.cantidad).toFixed(2))
      }
    },
    quitarProducto (state, producto) {
      state.productos = state.productos.filter(p => p.producto.codigo !== producto.producto.codigo)
    },
    vaciarCarrito (state) {
      state.productos = []
      state.subTotalCompraLocal = 0
      state.subTotalCompraExt = 0
      state.totalCompraLocal = 0
      state.totalCompraExt = 0
      state.totalIgvExt = 0
      state.totalIgvLocal = 0
    },
    setCurrency (state, selectedCurrency) {
      state.selectedCurrency = selectedCurrency
    },
    setTotales (state) {
      state.subTotalCompraLocal = 0
      state.subTotalCompraExt = 0
      state.totalIgvExt = 0
      state.totalIgvLocal = 0

      state.productos.forEach(prod => {
        state.subTotalCompraLocal += parseFloat(prod.producto.totalLocal)
        state.subTotalCompraExt += parseFloat(prod.producto.totalExt)
      })

      state.totalIgvExt = parseFloat((state.subTotalCompraExt * 0.18).toFixed(2))
      state.totalIgvLocal = parseFloat((state.subTotalCompraLocal * 0.18).toFixed(2))

      state.totalCompraExt = parseFloat((state.subTotalCompraExt + state.totalIgvExt).toFixed(2))
      state.totalCompraLocal = parseFloat((state.subTotalCompraLocal + state.totalIgvLocal).toFixed(2))
    }
  },
  actions: {
    agregarProducto ({ commit }, producto) {
      commit('agregarProducto', producto)
      commit('setTotales')
    },
    quitarProducto ({ commit }, producto) {
      commit('quitarProducto', producto)
      commit('setTotales')
    },
    actualizarProducto ({ commit }, producto) {
      commit('actualizarProducto', producto)
      commit('setTotales')
    },
    vaciarCarrito ({ commit }) {
      commit('vaciarCarrito')
      commit('setTotales')
    },
    cambiarMoneda ({ commit }, selectedCurrency) {
      commit('setCurrency', selectedCurrency)
    },
    verificarProductoEnCarrito ({ state }, producto) {
      return state.productos.some(p => p.producto.codigo === producto.codigo)
    }
  }
})
