import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import loading from '@/store/admin/loading'
import user from '@/store/common/user'
import carrito from '@/store/catalogo/carrito'

const store = createStore({
  modules: {
    loading,
    user,
    carrito
  },
  plugins: [createPersistedState({
    paths: ['user', 'carrito']
  })]
})

export default store
