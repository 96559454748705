const environment = 'production'

const apiBaseUrlDev = 'http://api.nexus.local'
const apiBaseUrlProd = 'https://api.nexus.webcodes.dev'

let API_BASE_URL

if (environment === 'development') {
  API_BASE_URL = apiBaseUrlDev
} else if (environment === 'production') {
  API_BASE_URL = apiBaseUrlProd
}

export { API_BASE_URL }
