<template>
    <footer id="footer" class="footer-adm">
        <div class="copyright">
            &copy; Copyright <strong><span>Catálogo {{currentYear}}</span></strong>
        </div>
    </footer>
</template>

<script>
export default {
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
}
</script>
