import axios from 'axios'
import { API_BASE_URL } from '@/config.js'

axios.defaults.withCredentials = true

const apiClient = axios.create({
  baseURL: API_BASE_URL
})

export async function get (url, config) {
  try {
    const response = await apiClient.get(url, config)
    return response
  } catch (error) {
    return error.response
  }
}

export async function post (url, data, config) {
  try {
    const response = await apiClient.post(url, data, config)
    return response
  } catch (error) {
    return error.response
  }
}
