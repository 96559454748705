import { get, post } from '@/helpers/HttpHelper'

export class LoginService {
  async login (credentials) {
    const response = await post('/login', credentials)
    return Promise.resolve(response.data)
  }

  async login_n (credentials) {
    const response = await post('/login_n', credentials)
    return Promise.resolve(response.data)
  }

  async isSessionValid () {
    const response = await get('/validar_sesion')
    return Promise.resolve(response.data)
  }

  async recuperarClave (credentials) {
    const response = await post('/recuperar_clave', credentials)
    return Promise.resolve(response.data)
  }

  async enviarCodigoConfirmacion (credentials) {
    const response = await post('/enviar_codigo_confirmacion', credentials)
    return Promise.resolve(response.data)
  }

  async verificarCodigoConfirmacion (credentials) {
    const response = await post('/verificar_codigo_confirmacion', credentials)
    return Promise.resolve(response.data)
  }
}
