import { LoginService } from '@/services/LoginService.js'
import store from '@/store/store.js'

const loginService = new LoginService()

export async function isSessionValid () {
  const isLoggedIn = store.state.user.isLoggedIn
  const response = await loginService.isSessionValid()
  if (isLoggedIn && response.sesionVigente === true) {
    return { valid: true, message: '' }
  } else if (isLoggedIn && response.sesionVigente === false) {
    return { valid: false, message: 'Sesión activa pero no vigente' }
  } else {
    return { valid: false, message: '' }
  }
}

export function hasPermissionGuard () {
  const hasPermission = true
  return hasPermission
}
