export default ({
  namespaced: true,
  state: {
    isLoading: false
  },
  mutations: {
    showPreloader (state) {
      state.isLoading = true
    },
    hidePreloader (state) {
      state.isLoading = false
    }
  },
  actions: {
    showPreloader ({ commit }) {
      commit('showPreloader')
    },
    hidePreloader ({ commit }) {
      commit('hidePreloader')
    }
  }
})
