<script setup>
import Header from '@/components/catalogo/common/header-catalogo.vue'
import Footer from '@/components/catalogo/common/footer-catalogo.vue'
</script>

<template>
  <Toast />
  <Header />
  <RouterView />
  <Footer />
</template>
