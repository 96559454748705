<template>
  <div class="preloader-container">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
</template>

<style>
.preloader-container {
  position: fixed; /* O absolute, según tu necesidad */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Fondo gris con transparencia */
  z-index: 9999; /* Asegura que se sobreponga a otros elementos */
}

.spinner-border {
  /* Centrado del spinner */
  /* Puedes agregar aquí estilos específicos para el spinner si es necesario */
}
</style>
