<template>
    <aside id="sidebar" class="sidebar">

        <ul class="sidebar-nav" id="sidebar-nav">

            <li class="nav-item">
                <router-link to="/admin/productos" class="nav-link" :class="{'collapsed': $route.name !== 'productos'}">
                    <font-awesome-icon icon="motorcycle" class="me-3" />
                    <span>Productos</span>
                </router-link>
            </li>

            <!-- <li class="nav-item">
                <router-link to="/admin/usuarios" class="nav-link" :class="{'collapsed': $route.name !== 'usuarios'}">
                    <font-awesome-icon icon="users" class="me-3" />
                    <span>Usuarios</span>
                </router-link>
            </li> -->

        </ul>

    </aside>
</template>
